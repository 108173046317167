<template>
  <div>
    <v-skeleton-loader class="mx-auto ml-2 mr-2" type="card" :loading="loading">
      <!-- <v-container fluid class="mb-4"> -->
      <CommunityInfo
        :communityInfo="communityInfo"
        @onRelatedCommunityChanged="showRelatedCommunity"
      />
    </v-skeleton-loader>
    <InternalBreadcrumbs :breadcrumbs="breadcrumbs" />
    <v-card
      elevation="0"
      v-if="permissions && permissions.invoice && permissions.invoice.canView"
    >
      <v-card-title class="orange-bg white--text">
        <v-row>
          <v-col cols="12" md="2">
            Invoices
          </v-col>
          <v-col cols="12" md="3">
            <v-select
              dense
              v-model="viewPeriod"
              :items="viewPeriodItems"
              item-text="name"
              item-value="value"
              label="Choose Period..."
            >
            </v-select>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-actions> </v-card-actions>
      <v-card-text>
        <!-- Start Invoices section -->

        <v-data-table
          :headers="headers"
          :items="records"
          item-key="id"
          :loading="isLoading"
          :server-items-length="totalRecords"
          :options.sync="options"
          loading-text="Loading... Please wait"
          class="elevation-1"
        >
          <template #[`item.fileName`]="{ item }">
            <v-tooltip bottom>
              <template #activator="{on, attrs}">
                <v-btn
                  v-if="!item.fileUrl"
                  class="ma-2"
                  text
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click.stop="downloadInvoice(item.id)"
                >
                  <v-icon color="red">mdi-download</v-icon>
                </v-btn>
                <a
                  v-else
                  v-bind="attrs"
                  v-on="on"
                  class="ma-2 pa-2"
                  target="_blank"
                  style="display: inline-block;"
                  :href="item.fileUrl"
                >
                  <v-icon color="red">mdi-download</v-icon>
                </a>
              </template>
              <span>Download Invoice</span>
            </v-tooltip>
            <!-- <a target="_blank" :href="item.fileUrl">{{ item.fileName }}</a> -->
          </template>
          <template #[`item.invoiceDate`]="{ item }">
            {{ formatDateUs(item.invoiceDate) }}
          </template>
        </v-data-table>

        <!-- End invoices section -->
      </v-card-text>
    </v-card>
    <!-- </v-container> -->
  </div>
</template>

<script>
import CommunityInfo from "@/view/components/customerView/CommunityInfo.vue";
import InternalBreadcrumbs from "@/view/components/DashboardInternalBreadcrumbs.vue";
import { GET_QUERY, API_CUSTOMERS } from "@/core/store/crud.module";
import crud from "@/core/helpers/crud";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
// import { API_USERS } from "@/core/store/crud.module";
import dateFormatter from "@/core/helpers/dateFormatter";
import stringFormatter from "@/core/helpers/stringFormatter";
import { validationMixin } from "vuelidate";
// import dataModels from "@/core/helpers/datamodels.js";
// import { required } from "vuelidate/lib/validators";
import moment from "moment";
import permissionsHelper from "@/core/helpers/permissionsHelper";

export default {
  mixins: [validationMixin],
  components: {
    CommunityInfo,
    InternalBreadcrumbs
  },
  data: () => ({
    headers: [
      { text: "Name", value: "title", class: "orange-bg" },
      { text: "Date", value: "invoiceDate", class: "orange-bg" },
      { text: "Invoice", value: "fileName", class: "orange-bg" }
    ],
    communityInfo: {},
    viewPeriod: "YTD",
    viewPeriodItems: [
      { name: "View last month", value: "LM" },
      { name: "View last quarter", value: "LQ" },
      { name: "View last year", value: "LY" },
      { name: "View year to date", value: "YTD" }
      // { name: "Custom date", value: "CD" }
    ],
    options: {
      page: 1,
      itemsPerPage: 10,
      sortBy: ["invoiceDate"],
      sortDesc: [true]
    },
    isLoading: false,
    loading: false,
    snackbarContent: "",
    snackbarColor: "#F06835",
    breadcrumbs: [],
    records: [],
    totalRecords: 0,
    customDateMenu: false,
    customDates: [],
    formattedCustomDates: "",
    permissions: {},
    baseUrl: `${process.env.VUE_APP_DASHBOARD_URL}`
  }),
  props: {
    customerId: {
      type: [Number, String],
      default: 0
    }
  },
  validations() {
    return true;
    // return {
    //   billingInfo: {
    //     subscription: {
    //       nextBillingDates: {
    //         required,
    //         minDate: value =>
    //           dateFormatter.convertToMilliseconds(value) >
    //           dateFormatter.convertToMilliseconds(new Date())
    //       }
    //     }
    //   }
    // };
  },
  watch: {
    options: async function() {
      // this.isLoading = true;
      // if (this.previousPage < this.options.page && this.options.page !== 1) {
      //   this.setStartingAfter();
      // } else if (
      //   this.previousPage > this.options.page &&
      //   this.options.page !== 1
      // ) {
      //   this.setEndingBefore();
      // } else {
      //   this.startingAfter = "";
      //   this.endingBefore = "";
      // }
      // this.isLoading = false;

      await this.getRecords();
    },
    viewPeriod: async function() {
      await this.getRecords();
    }
  },
  async mounted() {
    await this.getRecords();
  },
  async created() {
    await this.getComunityInfo();
    await permissionsHelper.getPermissions().then(this.getPermissions);
  },
  methods: {
    async downloadInvoice(invoiceId) {
      let pdfMake = require("pdfmake/build/pdfmake.js");
      if (pdfMake.vfs == undefined) {
        var pdfFonts = require("pdfmake/build/vfs_fonts.js");
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
      }

      // var htmlToPdfmake = require("html-to-pdfmake");

      let self = this;
      let url = `${API_CUSTOMERS}/${self.customerId}/invoices/${invoiceId}`;

      let invoiceResponse = await self.$store.dispatch(GET_QUERY, {
        listName: url
      });
      // console.log(invoiceResponse);

      let invoice = invoiceResponse.data.aspireInvoice;

      // // const {convert} = require("html-to-text");
      // let myHTML = invoice.invoiceOpportunities[0].description;

      // var strippedHtml = myHTML.replace(/<[^>]+>/g, "");

      pdfMake.fonts = {
        // yourFontName: {
        //   normal: 'https://example.com/fonts/fontFile.ttf',
        //   bold: 'https://example.com/fonts/fontFile2.ttf',
        //   italics: 'https://example.com/fonts/fontFile3.ttf',
        //   bolditalics: 'https://example.com/fonts/fontFile4.ttf'
        // },
        Fontello: {
          normal: self.baseUrl + "/media/fontello-bcdeccb8/font/fontello.ttf",
          bold: self.baseUrl + "/media/fontello-bcdeccb8/font/fontello.ttf",
          italics: self.baseUrl + "/media/fontello-bcdeccb8/font/fontello.ttf",
          bolditalics:
            self.baseUrl + "/media/fontello-bcdeccb8/font/fontello.ttf"
        },

        //download default Roboto font from cdnjs.com
        Roboto: {
          normal:
            "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
          bold:
            "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
          italics:
            "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
          bolditalics:
            "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf"
        },
        Arial: {
          normal:
            "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
          bold:
            "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
          italics:
            "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
          bolditalics:
            "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf"
        }
      };

      // let serviceDateModified = new Date(self.orangeReport.serviceDate)
      //   .toISOString()
      //   .substr(0, 10);

      let invoiceItemsBody = [];
      let header = [];
      header.push({
        text: "QTY",
        fillColor: "#778799",
        color: "white",
        alignment: "right",
        border: [false, false, false, true]
      });
      header.push({
        text: "ITEM",
        fillColor: "#778799",
        color: "white",
        alignment: "left",
        border: [false, false, false, true]
      });
      header.push({
        text: "EXT PRICE",
        fillColor: "#778799",
        color: "white",
        alignment: "center",
        border: [false, false, false, true]
      });
      header.push({
        text: "LINE TOTAL",
        fillColor: "#778799",
        color: "white",
        alignment: "center",
        border: [false, false, false, true]
      });

      invoiceItemsBody.push(header);

      if (
        invoice.invoiceOpportunities &&
        invoice.invoiceOpportunities.length > 0
      ) {
        invoice.invoiceOpportunities.forEach(function(item) {
          // let convertedDescr = htmlToPdfmake(item.description);
          // console.log(convertedDescr)
          let descriptionStr = item.description
            .replace(/<br[/]>/g, "\n")
            .replace(/<div/g, "\n<div")
            .replace(/<p/g, "\n<p")
            .replace(/<strong>/g, "[strong]")
            .replace(/<[^>]+>/g, "")
            .replace(/\n\n/g, "\n")
            .replace("\n", " ")
            .replace(/&nbsp;/g, " ");

          let descrptionStringArray = descriptionStr.split("\n");
          // console.log(descrptionStringArray);
          for (let i = 0; i < descrptionStringArray.length; i++) {
            let row = [];
            row.push({ text: "", border: [false, false, false, false] });
            let tmp = descrptionStringArray[i];
            if (tmp.includes("[strong]"))
              row.push({
                text: descrptionStringArray[i].replace("[strong]", ""),
                bold: true,
                border: [false, false, false, false]
              });
            else
              row.push({
                text: descrptionStringArray[i],
                border: [false, false, false, false]
              });
            if (i == 0) {
              row.push({
                alignment: "right",
                text:
                  "$" +
                  item.amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,"),
                bold: true,
                border: [false, false, false, false]
              });
              row.push({
                alignment: "right",
                text:
                  "$" +
                  item.amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,"),
                bold: true,
                border: [false, false, false, false]
              });
            } else {
              row.push({ text: "", border: [false, false, false, false] });
              row.push({ text: "", border: [false, false, false, false] });
            }

            invoiceItemsBody.push(row);
          }

          if (
            item.invoiceOpportunityServices &&
            item.invoiceOpportunityServices.length > 0
          ) {
            item.invoiceOpportunityServices.forEach(function(subItem) {
              let row = [];
              row.push({ text: "", border: [false, false, false, false] });
              row.push({
                margin: [5, 0, 0, 0],
                italics: true,
                text: subItem.description
                  .replace(/</g, "\n<")
                  .replace(/<[^>]+>/g, "")
                  .replace(/\n\n/g, "\n")
                  .replace("\n", " "),
                border: [false, false, false, false]
              });
              row.push({
                alignment: "right",
                italics: true,
                text:
                  "$" +
                  subItem.amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,"),
                border: [false, false, false, false]
              });
              row.push({
                alignment: "right",
                italics: true,
                text:
                  "$" +
                  subItem.amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,"),
                border: [false, false, false, false]
              });
              invoiceItemsBody.push(row);
            });
          }
        });
      }

      invoiceItemsBody.push([
        { text: "", border: [false, true, false, false] },
        { text: "Total", bold: true, border: [false, true, false, false] },
        {
          alignment: "right",
          text:
            "$" +
            invoice.origAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,"),
          bold: true,
          border: [false, true, false, false]
        },
        {
          alignment: "right",
          text:
            "$" +
            invoice.origAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,"),
          bold: true,
          border: [false, true, false, false]
        }
      ]);

      invoiceItemsBody.push([
        { text: "", border: [false, false, false, false] },
        {
          text: "Amount Paid",
          bold: true,
          border: [false, false, false, false]
        },
        { text: "", bold: true, border: [false, false, false, false] },
        {
          alignment: "right",
          text:
            "$" +
            (invoice.origAmount - invoice.amountRemaining)
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,"),
          bold: true,
          border: [false, false, false, false]
        }
      ]);

      invoiceItemsBody.push([
        { text: "", border: [false, false, false, false] },
        {
          text: "Total Balance",
          bold: true,
          border: [false, false, false, false]
        },
        { text: "", bold: true, border: [false, false, false, false] },
        {
          alignment: "right",
          text:
            "$" +
            invoice.amountRemaining
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,"),
          bold: true,
          border: [false, false, false, false]
        }
      ]);

      let branch = {
        logo: "yardniqueLogo", //self.baseUrl + "/media/logos/yardnique-logo.png",
        address: "10014 Chapel Hill Road",
        city: "Morrisville",
        state: "NC",
        zip: "27560"
      };

      switch (invoice.branchCode) {
        case "LMG":
        case "LMNC":
        case "LMSC":
          branch = {
            logo: "landmarkLogo", //self.baseUrl + "/media/logos/landmark-logo.png",
            address: "559 S Cemetery St",
            city: "Norcross",
            state: "GA",
            zip: "30071"
          };
          break;
        case "NTG":
          branch = {
            logo: "nativegreenLogo", //self.baseUrl + "/media/logos/nativegreen-logo.png",
            address: "3095 Oleander Ave",
            city: "Fort Pierrce",
            state: "FL",
            zip: "34982"
          };
          break;
        case "OCO":
        case "LIL":
        case "MAR":
        case "SUN":
          branch = {
            logo: "nscapesLogo", //self.baseUrl + "/media/logos/nscapes-logo.png",
            address: "1270 Turner Rd, Ste. A",
            city: "Lilburn",
            state: "GA",
            zip: "30047"
          };
          break;
        case "PHX":
          branch = {
            logo: "phoenixLogo", //self.baseUrl + "/media/logos/phoenix-logo.png",
            address: "1728 Castle Hayne Road",
            city: "Wilmington",
            state: "NC",
            zip: "28401"
          };
          break;
        case "TMCEN":
        case "TMG":
        case "TMNE":
        case "TMSE":
        case "Orland":
          // branch = {
          //   logo: "trimacLogo", //self.baseUrl + "/media/logos/trimac-logo.png",
          //   address: "PO BOX 8699",
          //   city: "Fleming Island",
          //   state: "FL",
          //   zip: "32006"
          // };
          // break;
          branch = {
            logo: "yardniqueLogo", //self.baseUrl + "/media/logos/yardnique-logo.png",
            address: "10014 Chapel Hill Road",
            city: "Morrisville",
            state: "NC",
            zip: "27560"
          };
          break;
        case "APX":
        case "CHS":
        case "CSTI":
        case "CRD":
        case "ADM":
        case "GRG":
        case "GRB":
        case "APS":
        case "MOR":
        case "RIR":
        case "MRT":
        case "OCA":
        case "SAVCOL":
        case "ILM":
          branch = {
            logo: "yardniqueLogo", //self.baseUrl + "/media/logos/yardnique-logo.png",
            address: "10014 Chapel Hill Road",
            city: "Morrisville",
            state: "NC",
            zip: "27560"
          };
          break;
      }

      let docDefinition = {
        images: {
          yardniqueLogo: self.baseUrl + "/media/logos/yardnique-logo.png",
          trimacLogo: self.baseUrl + "/media/logos/trimac-logo.png",
          phoenixLogo: self.baseUrl + "/media/logos/phoenix-logo.png",
          nscapesLogo: self.baseUrl + "/media/logos/nscapes-logo.png",
          nativegreenLogo: self.baseUrl + "/media/logos/nativegreen-logo.png",
          landmarkLogo: self.baseUrl + "/media/logos/landmark-logo.png"
        },
        pageSize: "LETTER",
        pageMargins: [20, 20, 20, 20],
        content: [
          {},
          {
            columns: [
              {
                width: "*",
                stack: [
                  {
                    columns: [
                      {
                        width: "auto",
                        stack: [
                          {
                            image: branch.logo,
                            width: 100,
                            margin: [5, 0, 5, 0]
                          }
                        ]
                      },
                      {
                        width: "*",
                        stack: [
                          {
                            text: branch.address,
                            alignment: "left",
                            margin: [10, 10, 0, 0],
                            fontSize: 10
                          },
                          {
                            text:
                              branch.city +
                              " ," +
                              branch.state +
                              " " +
                              branch.zip,
                            alignment: "left",
                            margin: [10, 0, 0, 0],
                            fontSize: 10
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                width: "auto",
                stack: [
                  {
                    text: "INVOICE",
                    alignment: "center",
                    margin: [5, 5, 5, 5]
                  },
                  {
                    table: {
                      body: [
                        [
                          {
                            text: "Date",
                            fillColor: "#778799",
                            color: "white",
                            alignment: "center"
                          },
                          {
                            text: "Invoice No.",
                            fillColor: "#778799",
                            color: "white"
                          }
                        ],
                        [
                          moment(invoice.invoiceDate).format("MM/D/YYYY"),
                          invoice.invoiceNumber
                        ],
                        [
                          {
                            text: "Terms",
                            fillColor: "#778799",
                            color: "white",
                            alignment: "center"
                          },
                          {
                            text: "Due Date",
                            fillColor: "#778799",
                            color: "white",
                            alignment: "center"
                          }
                        ],
                        [invoice.paymentTermsName, invoice.dueDate || "---"]
                      ]
                    }
                  }
                ]
              }
            ]
          },
          "\n",
          {
            columns: [
              {
                width: "*",
                text: ""
              },
              {
                width: "auto",
                table: {
                  widths: [200],
                  body: [
                    [
                      {
                        text: "Property",
                        fillColor: "#778799",
                        color: "white",
                        alignment: "center"
                      }
                    ],
                    [
                      `${invoice.propertyName}\n${self.communityInfo.branchOfficeStaff?.communityAddress}\n${self.communityInfo.branchOfficeStaff?.communityCity}, ${self.communityInfo.branchOfficeStaff?.communityStateCode}, ${self.communityInfo.branchOfficeStaff?.communityPostalCode}`
                    ]
                  ]
                }
              }
            ]
          },
          "\n",
          {
            columns: [
              {
                width: "*",
                table: {
                  widths: [200],
                  body: [
                    [
                      {
                        text: "Bill To",
                        fillColor: "#778799",
                        color: "white",
                        alignment: "center"
                      }
                    ],
                    [
                      invoice.billingContactName +
                        "\n\n" +
                        invoice.companyName +
                        "\n" +
                        invoice.addressLine1 +
                        "\n" +
                        invoice.city +
                        ", " +
                        invoice.stateProvinceCode +
                        " " +
                        invoice.zipCode
                    ]
                  ]
                }
              },
              {
                width: "auto",
                table: {
                  body: [
                    [
                      {
                        text: "Amount Due",
                        fillColor: "#778799",
                        color: "white",
                        alignment: "center"
                      },
                      {
                        text: "Customer PO #",
                        fillColor: "#778799",
                        color: "white",
                        alignment: "center"
                      }
                    ],
                    [
                      "$" +
                        (invoice.amount || 0)
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, "$&,"),
                      ""
                    ]
                  ]
                }
              }
            ]
          },
          {
            alignment: "center",
            text: "\n\nPlease detach top portion and return with your payment.",
            italics: true
          },
          {
            alignment: "center",
            text:
              "----------------------------------------------------------------------"
          },
          "\n",
          {
            // layout: "headerLineOnly",
            table: {
              widths: [20, "*", 50, 50],
              headerRows: 1,

              body: invoiceItemsBody
            }
          },
          "\n\n"
        ],
        defaultStyle: {
          fontSize: 8,
          bold: false
        },
        styles: {
          heading: {
            color: "#E06521",
            bold: true,
            margin: [0, 0, 0, 5]
          },
          icon: { font: "Fontello" },
          photos: {
            margin: [0, 10, 0, 10]
          },
          mt5: { margin: [0, 5, 0, 0] },
          mt10: { margin: [0, 10, 0, 0] },
          mb5: { margin: [0, 0, 0, 5] },
          mb10: { margin: [0, 0, 0, 10] },
          my5: { margin: [0, 5, 0, 5] },
          my10: { margin: [0, 10, 0, 10] }
        }
      };
      pdfMake.createPdf(docDefinition).open();
    },
    getPermissions(permissionsObj) {
      this.permissions = permissionsObj;
    },
    showRelatedCommunity(communityId) {
      this.$router.push({
        name: "CustomerView",
        params: { customerId: communityId }
      });
    },
    async getRecords() {
      this.isLoading = true;

      let self = this;
      let url = `${API_CUSTOMERS}/${this.customerId}/invoices`;

      await this.$store
        .dispatch(GET_QUERY, {
          listName: url
        })
        .then(response => {
          if (response.status >= 200 && response.status <= 204) {
            const { sortBy, sortDesc, page, itemsPerPage } = this.options;

            let currentDate = new Date();
            currentDate.setHours(0, 0, 0);
            let startPeriod = new Date(new Date().setDate(-30));
            startPeriod.setHours(0, 0, 0);
            let endPeriod = new Date();
            endPeriod.setHours(0, 0, 0);
            switch (self.viewPeriod) {
              case "LY":
                startPeriod = new Date(currentDate.getFullYear() - 1, 0, 1);
                endPeriod = new Date(currentDate.getFullYear() - 1, 11, 31);
                break;
              case "LQ":
                startPeriod = this.getLastQuarter().startDate;
                break;
              case "YTD":
                startPeriod = new Date(currentDate.getFullYear(), 0, 1);
                break;
              case "CD":
                if (self.customDates.length > 0) {
                  startPeriod = new Date(self.customDates[0]);
                  if (self.customDates.length > 1)
                    endPeriod = new Date(self.customDates[1]);
                }

                break;
              //case "LM":break; apply in default
              default:
                break;
            }

            let items = response.data;

            items = items.filter(
              el =>
                new Date(el.invoiceDate) >= new Date(startPeriod) &&
                new Date(el.invoiceDate) <= new Date(endPeriod)
            );

            self.totalRecords = items.length;

            if (sortBy.length === 1 && sortDesc.length === 1) {
              items = items.sort((a, b) => {
                const sortA = a[sortBy[0]];
                const sortB = b[sortBy[0]];

                if (sortDesc[0]) {
                  if (sortA < sortB) return 1;
                  if (sortA > sortB) return -1;
                  return 0;
                } else {
                  if (sortA < sortB) return -1;
                  if (sortA > sortB) return 1;
                  return 0;
                }
              });
            }

            if (itemsPerPage > 0) {
              items = items.slice(
                (page - 1) * itemsPerPage,
                page * itemsPerPage
              );
            }

            self.records = items;
          } else {
            self.$snackbar.showMessage({
              content:
                response.data ||
                "Cannot receive a list of invoices. Something went wrong!",
              color: "red"
            });
          }

          self.isLoading = false;
        });
    },
    async getComunityInfo() {
      this.loading = true;

      await this.$store
        .dispatch(GET_QUERY, {
          listName: API_CUSTOMERS,
          id: this.customerId
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            this.communityInfo = crud.getPopulatedComunityInfo(response.data);
            this.createBreadcrumb();
            this.setInternalBreadcrumbs();
          } else {
            this.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }

          this.loading = false;
        });
    },
    async setInternalBreadcrumbs() {
      this.breadcrumbs = [
        {
          title: this.communityInfo.name,
          route: "/customerView/" + this.customerId
        },
        { title: "Invoices" }
      ];
    },
    // setStartingAfter() {
    //   if (this.userInvoices && this.userInvoices.length > 0) {
    //     this.startingAfter = this.userInvoices[this.userInvoices.length - 1].id;
    //     this.endingBefore = "";
    //   }
    // },
    // setEndingBefore() {
    //   if (this.userInvoices && this.userInvoices.length > 0) {
    //     this.endingBefore = this.userInvoices[0].id;
    //     this.startingAfter = "";
    //   }
    // },
    createBreadcrumb() {
      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: this.communityInfo.name,
          route: "/customerView/" + this.customerId
        },
        { title: "Invoices" }
      ]);
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
    },
    formatDate(date) {
      return dateFormatter.formatEditDate(date);
    },
    formatDateUs(date) {
      // return moment(date).format("MM/DD/YYYY");
      return dateFormatter.formatDateUs(date);
    },
    capitalizeFirstLetter(string) {
      return stringFormatter.capitalizeFirstLetter(string);
    }
  },
  computed: {
    // totalItems() {
    //   if (
    //     (this.userInvoices &&
    //       this.userInvoices.length === this.options.itemsPerPage &&
    //       this.previousPage <= this.options.page &&
    //       this.hasMore) ||
    //     (this.options.page === 1 &&
    //       this.userInvoices.length === this.options.itemsPerPage)
    //   ) {
    //     return (this.options.page + 1) * this.options.itemsPerPage;
    //   }
    //   return this.options.page * this.options.itemsPerPage;
    // }
  }
};
</script>
