var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-skeleton-loader',{staticClass:"mx-auto ml-2 mr-2",attrs:{"type":"card","loading":_vm.loading}},[_c('CommunityInfo',{attrs:{"communityInfo":_vm.communityInfo},on:{"onRelatedCommunityChanged":_vm.showRelatedCommunity}})],1),_c('InternalBreadcrumbs',{attrs:{"breadcrumbs":_vm.breadcrumbs}}),(_vm.permissions && _vm.permissions.invoice && _vm.permissions.invoice.canView)?_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"orange-bg white--text"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_vm._v(" Invoices ")]),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{attrs:{"dense":"","items":_vm.viewPeriodItems,"item-text":"name","item-value":"value","label":"Choose Period..."},model:{value:(_vm.viewPeriod),callback:function ($$v) {_vm.viewPeriod=$$v},expression:"viewPeriod"}})],1)],1)],1),_c('v-card-actions'),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.records,"item-key":"id","loading":_vm.isLoading,"server-items-length":_vm.totalRecords,"options":_vm.options,"loading-text":"Loading... Please wait"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.fileName",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!item.fileUrl)?_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"text":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.downloadInvoice(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-download")])],1):_c('a',_vm._g(_vm._b({staticClass:"ma-2 pa-2",staticStyle:{"display":"inline-block"},attrs:{"target":"_blank","href":item.fileUrl}},'a',attrs,false),on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-download")])],1)]}}],null,true)},[_c('span',[_vm._v("Download Invoice")])])]}},{key:"item.invoiceDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateUs(item.invoiceDate))+" ")]}}],null,true)})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }